@charset "utf-8";
@import 'import/var';


/**************************
	drawer
*/

$drawer_width: 100%;
$drawer_li_top_height: 61px;
$drawer_li_height: 70px;
$drawer_color: #fff;
$drawer_background-color: #692422;
$drawer_hover_background-color: #3e0f0d;
$drawer_hover_color: #fff;
$drawer_li_border_color: #ddd;
$drawer_li_border_width: 1px;
// $drawer_arrow_color: #ff6600;
$drawer_mm_next_arrow_color: #fff;
$drawer_mm_next_hover_arrow_color: #fff;

$drawer_li_height: 50px;


/**************************
	drawer
*/
.mm-menu {
	background-color: $drawer_background-color;
}
#drawer {
	// visibility: hidden;
	z-index: 1000;
	width: $drawer_width;

	.js-toggle {
		display: none;
	}

	.gnav__sub_image {
		display: none;
	}

	.drawer {
		&__bottom {
			padding: 0;
			padding-top: 10px;
			border-bottom: 0 none;
			> * {
				padding: 0;
			}
			a {
				@include on_opacity;
			}
			.link {
				padding-left: 30px;

				&::after {
					top: .55em;
					// background-image: url('../ing/icon/arrow/link.png');
				}
			}
		}

		&__search {
			padding: 10px 20px;
			// .search {
			// 	height: 40px;
			// 	padding-right: 40px;
			// 	&__text {
			// 		height: 40px;
			// 		line-height: 40px;
			// 		font-size: 16px;
			// 		padding-right: 42px;
			// 	}
			// 	&__submit {
			// 		top: 0;
			// 		right: 0;
			// 		width: 40px;
			// 		height: 40px;
			// 	}
			// }
		}
	}

	//- パネル余白
	// .mm-panels {
	// 	background-color: $drawer_background-color;
	// 	> .mm-panel {
	// 		padding-left: 10px;
	// 		padding-right: 10px;
	// 	}
	// }
	li {
		box-sizing: border-box;
		border-bottom: $drawer_li_border_width solid $drawer_li_border_color;

		&.mm-selected > a,
		.drawer__a:hover,
		.a1:hover,
		.a2:hover {
			background-color: $drawer_hover_background-color !important;
			color: $drawer_hover_color !important;

			// > .icon {
			// 	background-color: rgba(#fff, .7);
			// }
		}
		&:hover > .mm-next {
			//- 矢印
			&::after {
				border-color: $drawer_mm_next_hover_arrow_color;
			}
		}
	}
	.a1,
	.a2,
	.drawer__a,
	.drawer__bottom__li a {
		color: $drawer_color;
		padding: 10px 20px;
		min-height: 40px;
		box-sizing: border-box;
		display: flex;
		align-items: center;

		justify-content: flex-start;
		font-size: 15px;


		span.main {
			margin-right: 10px;
			height: 25px;
			display: block;

			img {
				height: 100%;
			}
		}

		span.sub {
			position: relative;
			top: 2px;
			left: 0;
			display: block;
			font-size: 13px;
			img {
				height: 10px;
				display: block;
			}
		}
		.sns_icon {
			width: 20px;
			margin-right: 5px;
		}
	}
	// .drawer__bottom_menu a,
	// .a1,
	// .a2 {
	// 	&::before {
	// 		@include bg-block('/common/img/icon/arrow/link.png', 10px, 12px);
	// 		margin-right: 8px;
	// 		margin-left: 5px;
	// 	}
	// }

	//- 2階層目以降の上部タイトル
	.mm-navbar {
		// display: none;
		// border-bottom: 1px solid rgba(#fff, .4);
		// height: 50px;
		// margin-left: -10px;
		// margin-right: -10px;
		// a,
		// > * {
		// 	color: #222;
		// 	font-size: 15px;
		// 	padding: 10px;
		// 	min-height: 50px;
		// 	line-height: 30px;
		// 	box-sizing: border-box;
		// }
		// opacity: 0;
		width: 100%;
		position: absolute;
		top: $sp_header_height;
		padding: 0;
		height: 50px;
		line-height: 50px;
		left: 0;
		z-index: 2;
		opacity: 1;
		.mm-btn {
			// display: none;
			top: 0;
			height: 50px;
		}
		.mm-title {
			color: $drawer_color;
			font-size: $fontsize18;
			padding: 0;
			width: 100%;
			height: 50px;
			background-color: $drawer_background-color;
		}
	}
	.drawer__a,
	.a1,
	.a2,
	.a3 {
		background-color: $drawer_background-color;
		display: flex;
		height: $drawer_li_height;
		justify-content: flex-start;
		align-items: center;
		width: 100%;
		//padding-left: 30px;
		position: relative;
		top: 0;
		left: 0;
		color:#fff;

		// > .icon {
		// 	position: absolute;
		// 	top: 50%;
		// 	left: 20px;
		// 	margin-top: -20px;

		// 	border-radius: 20px;

		// 	&,
		// 	a,
		// 	img {
		// 		width: 40px;
		// 		height: 40px;
		// 	}
		// }
		> .text {
			font-size: $fontsize15;
			line-height: 1.3;
		}
	}

	// .drawer {
	// 	&__head {
	// 		padding-top: 0;
	// 		padding-bottom: 0;
	// 	}
	// 	&__hero {
	// 		display: flex;
	// 		align-items: center;
	// 		background: url('/common/img/sp/menu/bg/hero.jpg') center center / cover no-repeat transparent;
	// 		height: 80px;
	// 		box-sizing: border-box;


	// 		img {
	// 			height: 40px;
	// 			margin-left: 6px;
	// 		}


	// 		&-about {
	// 			background-image: url('/common/img/sp/menu/bg/about.jpg');
	// 		}
	// 		&-resident_internal {
	// 			background-image: url('/common/img/sp/menu/bg/resident_internal.jpg');
	// 		}
	// 		&-resident_anesthesiology {
	// 			background-image: url('/common/img/sp/menu/bg/resident_anesthesiology.jpg');
	// 		}
	// 		&-medical_internship {
	// 			background-image: url('/common/img/sp/menu/bg/medical_internship.jpg');
	// 		}
	// 		&-medical_technician {
	// 			background-image: url('/common/img/sp/menu/bg/medical_technician.jpg');
	// 		}
	// 		&-office_employee {
	// 			background-image: url('/common/img/sp/menu/bg/office_employee.jpg');
	// 		}
	// 	}
	// }

	//- 下層へのリンク矢印部分
	.mm-next {
		background-color: #7d3533;
		//- 左の縦線
		&::before {
			display: none;
		}
		//- 矢印
		&::after {
			border-color: $drawer_mm_next_arrow_color;
			border-width: 1px;
			right: 25px;
		}
	}
	.mm-btn::after,
	.mm-btn::before {
		border-color: $drawer_color;
		border-width: 1px;
		left: 23px;
	}

	//- 
	li {
		&::after {
			border-color: transparent;
		}
		&:not(.drawer__head):not(.drawer__bottom) {
			&::after {
				border-color: rgba(#fff, .2);
			}
		}
	}

	//- li の border
	.mm-listview > li:not(.mm-divider)::after,
	.mm-listview.mm-border-offset > li::after,
	.mm-menu.mm-border-offset .mm-listview > li::after,
	.mm-listview.mm-border-full > li::after,
	.mm-menu.mm-border-full .mm-listview > li::after {
		left: 20px !important;
		right: 20px !important;
	}

	.mm-hasnavbar-top-2 .mm-panels,
	.mm-navbar-top-3 {
		top: 131px;
	}





	// init
	//- 1ページ目の上部タイトルを削除
	#mm-1 {
		&::before,
		&::after,
		.mm-navbar {
			display: none;
		}
	}

	//- 1ページ目以外の上部タイトル y位置
	// .mm-panel.mm-hasnavbar .mm-navbar {
	// 	position: relative;
	// 	margin-top: 11px;
	// }

	//- 上部の余白削除


	#mm-1 > .mm-navbar + .mm-listview {
		margin-top: $sp_header_height - 40px + 1;
	}
	.mm-panels > .mm-panel > .mm-listview:first-child,
	.mm-panels > .mm-panel > .mm-navbar + .mm-listview {
		// margin-top: -40px;
		margin-top: 71px;
	}
	.mm-panels > .mm-panel::after,
	.mm-panels > .mm-panel::before {
		display: none;
	}
}

#header {
	transform: none !important;
}


// .mm-opening #mm-blocker {
// 	background: #000 none repeat scroll 0 0;
// 	transition: background 1s ease 0.2s;
// 	opacity: 0.2;
// }
// html.mm-right.mm-opening .mm-slideout {
//     -webkit-transform: translate(-1 * $drawer_width, 0);
//     -moz-transform: translate(-1 * $drawer_width, 0);
//     -ms-transform: translate(-1 * $drawer_width, 0);
//     -o-transform: translate(-1 * $drawer_width, 0);
//     transform: translate(-1 * $drawer_width, 0);
// }
.search {
	$height: 40px;
	
	height: $height;
	width: 100%;
	position: relative;
	top: 0;
	left: 0;
	display: block;
	box-sizing: border-box;
	overflow: hidden;
	

	&__text {
		// border: 1px solid #ccc;
		border: 1px solid #8D7E6E;
		height: $height;
		line-height: $height;
		font-size: $fontsize16;
		width: 100%;
		background-color: #ededed;
		box-sizing: border-box;
		padding: 0 35px 0 10px;
		display: block;
		color: #8D7E6E;
		@media #{$sp} {
			font-size: 16px;
		}
	}
	&__submit {
		border: 1px solid #8D7E6E;
		cursor: pointer;
		position: absolute;
		top: 0;
		right: 1px;
		width: 40px;
		height: 40px;
		border: 0;
		padding: 0;
		margin: 0;
		box-sizing: border-box;
		background: url('../img/icon/search.png') center center / 30px 30px no-repeat #8D7E6E;
		box-sizing: border-box;
		overflow: hidden;
	}
}